<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
          <h1>{{this.singular}}: {{this.data.device.name}}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="isAllowed('device','u')" @click="isEditable = !isEditable">
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-form :disabled="!isEditable">
        <v-row>
          <v-col>
            <v-text-field
                label="Name"
                outlined
                v-model="data.device.name"
            />
            <v-text-field
                label="Location"
                outlined
                v-model="data.device.location"
            />
            <v-text-field
                label="Serial"
                outlined
                v-model="data.device.serial"
            />
            <v-select
                item-text="name"
                item-value="id"
                :items="data.deviceTypes"
                label="Device Type"
                outlined
                clearable
                v-model="data.device.deviceType"
            />
            <span v-if="this.data && this.data.device.deviceType==8">
              <p>Example for Print Server: {"key":"DrOGRttJF87FG7NCh0QdeeAIcZZkPWT5QbKkgZkA6GmcFdfweF6Zgh7qk9G20eafPAPVqPZB","address":"http://192.168.1.16:3020","isDefault":true}</p>
            </span>
            <span v-else>
              <p>Example for Printer: {"path":"/HP_LaserJet_Pro_M12w","jobTypes":"['invoice','deliverynote']","printServer":11,}</p>
            </span>
            <v-textarea
                name="Device Data"
                label="Data"
                v-model="data.device.data"
                outlined
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col v-if="isEditable">
          <v-btn @click="update">Save</v-btn>
          <v-btn v-if="isAllowed('device','d')" style="margin-left: 10px;" class="error" @click="deleteData">Delete</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters} from "vuex";
export default {
  data () {
    return {
      deleteDialog: false,
      deleteConfirmed: true,
      loader: true,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      max25chars: v => v.length <= 25 || 'Input too long!',
      editMode: false,
      data: {device: {}},
      singular: "Device",
      singularLower: "device",
      plural: "Devices",
      pluralLower: "devices",
      isEditable: false,
      employees: []
    }
  },
  async mounted(){
    try {
      this.loader = true;
      let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
      if(res.data.error) throw res.data.error

      this.data = res.data.data
      this.data.device.data = JSON.stringify(this.data.device.data)
    } catch (error) {
      console.error(error)
      this.snack(error.msg || error, "error");
    } finally {
      this.loader = false;
    }
  },
  computed: {
    ...mapGetters(['getEndpoint', "isAllowed"])
  },
  methods: {
    async update(){
      try {
        this.loader = true;
        this.data.device.data = JSON.parse(this.data.device.data)
        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data.device)
        if(res.data.error) throw res.data.error

        this.snack(`${this.singular} info Updated 🎉`, "success")
        this.isEditable = false;
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      } finally {
        this.loader = false;
      }
    },
    async deleteData(){
      try {
        this.loader = true
        let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
        if(res.data.error) throw res.data.error

        this.snack(this.singular+" Deleted 🎉", "success");

        await this.$router.go(-1)
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    }
  }
}
</script>
